<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="仓库编号" >
                <el-input v-model="search.cangKuBH" size="small" />
            </x-search-item>
            <x-search-item label="所属门店" >
                <el-input v-model="search.suoShuMenDian" size="small" />
            </x-search-item>
            <x-search-item label="仓库名称" >
                <el-input v-model="search.cangKuMC" size="small" />
            </x-search-item>
            <x-search-item label="启用状态" >
                <x-selector-one v-model="search.use" size="small" dictType="T_USE"/>
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border>
            <el-table-column width="50" label="序号" type="index" fixed/>
            <el-table-column width="180" prop="cangKuBH" label="仓库编号" />
            <el-table-column width="180" prop="chuangJianRQ" label="创建日期" />
            <el-table-column width="180" prop="suoShuMenDian" label="所属门店" />
            <el-table-column width="180" prop="cangKuMC" label="仓库名称" />
            <el-table-column width="180" prop="cangKuMJ" label="仓库面积" />
<!--            <el-table-column width="180" prop="suoZaiDiQu" label="所在地区" />-->
            <el-table-column width="180" prop="xiangXiDZ" label="详细地址" />
            <el-table-column width="180" prop="fuZeRen" label="负责人" />
            <el-table-column width="180" prop="fuZeRenID" label="负责人ID" />
            <el-table-column width="180" prop="fuZeRenShouJiHao" label="负责人手机号" />
            <el-table-column width="180" prop="beiZhu" label="备注" />
            <el-table-column width="180" label="是否默认仓库">
                <x-dict-show slot-scope="{row}" :code="row.shiFuMoRenCangKu" dictType="T_YES_OR_NO" />
            </el-table-column>
            <el-table-column width="180" label="启用状态">
                <x-dict-show slot-scope="{row}" :code="row.use" dictType="T_USE" />
            </el-table-column>
            <el-table-column width="100" align="center" label="操作" fixed="right">
                <el-button slot-scope="{row}" type="primary" size="mini" round @click="handleToDetail(row.id)">查看</el-button>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 详情页 -->
        <detail :detail="detail"/>
    </div>
</template>
<script>
    import * as service from "@/service/cggl/CangKu";
    import XTableBase from "@/components/x/XTableBase";
    import Detail from "@/view/cggl/ckgl/CangKuDetail";

    export default {
        name: "CangKuQuery",
        mixins: [XTableBase],
        components: {Detail},
        data() {
            this.refreshService = service.query;
            return {
                search: {
                    cangKuBH: '',
                    cangKuMC: '',
                    use: '',
                }
            }
        }
    }
</script>

<style scoped>
</style>